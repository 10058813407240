import './ConditionSpecification.css';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from 'react-router-dom';
import { FooterDecisionClearBotton } from '../Footer/FooterIcons/FooterDecisionClearBotton';

/**
 * この画面の各条件項目の設定画面から「戻る」で遷移するとき
 * location.stateで設定値と何処から戻ってきたかのpathを受け取る
 */
export const ConditionSpecification = () => {
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <div>
            <div className="condition_specification_title">条件検索</div>
            <div className="main_content_common">
                <div className="condition_specification_setting">
                    <p
                        onClick={() =>
                            navigate('/ExerciseMethodSelect', {
                                state: { path: '/ConditionSpecification' },
                            })
                        }
                    >
                        <div style={{ fontSize: '16px' }}>活用したい事</div>
                        <div style={{ color: '#FF3333' }}>別画面あり</div>
                        <div>
                            <ArrowForwardIosIcon
                                style={{ marginLeft: '50%', color: '#a0a0a0' }}
                            />
                        </div>
                    </p>
                    <p
                        onClick={() =>
                            navigate('/InterestingThingsSelect', {
                                state: { path: '/ConditionSpecification' },
                            })
                        }
                    >
                        <div>興味ある事</div>
                        <div style={{ color: '#FF3333' }}>別画面あり</div>
                        <div>
                            <ArrowForwardIosIcon
                                style={{ marginLeft: '50%', color: '#a0a0a0' }}
                            />
                        </div>
                    </p>
                    <p onClick={() => navigate('/AgeSelectionScreen')}>
                        <div>年齢</div>
                        <div style={{ color: '#FF3333' }}>別画面あり</div>
                        <div>
                            <ArrowForwardIosIcon
                                style={{ marginLeft: '50%', color: '#a0a0a0' }}
                            />
                        </div>
                    </p>
                    <p>
                        <div>性別</div>
                        <div>設定内容</div>
                        <div>
                            <ArrowForwardIosIcon
                                style={{ marginLeft: '50%', color: '#a0a0a0' }}
                            />
                        </div>
                    </p>
                    <p>
                        <div>職業</div>
                        <div>設定内容</div>
                        <div>
                            <ArrowForwardIosIcon
                                style={{ marginLeft: '50%', color: '#a0a0a0' }}
                            />
                        </div>
                    </p>
                    <p>
                        <div>職歴</div>
                        <div>設定内容</div>
                        <div>
                            <ArrowForwardIosIcon
                                style={{ marginLeft: '50%', color: '#a0a0a0' }}
                            />
                        </div>
                    </p>
                    <p>
                        <div>学歴</div>
                        <div>設定内容</div>
                        <div>
                            <ArrowForwardIosIcon
                                style={{ marginLeft: '50%', color: '#a0a0a0' }}
                            />
                        </div>
                    </p>
                    <p onClick={() => navigate('/ResidenceSelectionScreen')}>
                        <div>居住地</div>
                        <div style={{ color: '#FF3333' }}>別画面あり</div>
                        <div>
                            <ArrowForwardIosIcon
                                style={{ marginLeft: '50%', color: '#a0a0a0' }}
                            />
                        </div>
                    </p>
                    <p>
                        <div>出身地</div>
                        <div>設定内容</div>
                        <div>
                            <ArrowForwardIosIcon
                                style={{ marginLeft: '50%', color: '#a0a0a0' }}
                            />
                        </div>
                    </p>
                    <p>
                        <div style={{ fontSize: '14px' }}>交際ステータス</div>
                        <div>設定内容</div>
                        <div>
                            <ArrowForwardIosIcon
                                style={{ marginLeft: '50%', color: '#a0a0a0' }}
                            />
                        </div>
                    </p>
                    <p>
                        <div>身長</div>
                        <div>設定内容</div>
                        <div>
                            <ArrowForwardIosIcon
                                style={{ marginLeft: '50%', color: '#a0a0a0' }}
                            />
                        </div>
                    </p>
                    <p>
                        <div>体重</div>
                        <div>設定内容</div>
                        <div>
                            <ArrowForwardIosIcon
                                style={{ marginLeft: '50%', color: '#a0a0a0' }}
                            />
                        </div>
                    </p>
                    <p>
                        <div>年収</div>
                        <div>設定内容</div>
                        <div>
                            <ArrowForwardIosIcon
                                style={{ marginLeft: '50%', color: '#a0a0a0' }}
                            />
                        </div>
                    </p>
                    <p>
                        <div>性格</div>
                        <div>設定内容</div>
                        <div>
                            <ArrowForwardIosIcon
                                style={{ marginLeft: '50%', color: '#a0a0a0' }}
                            />
                        </div>
                    </p>
                </div>
            </div>
            <FooterDecisionClearBotton message="検索する" path="/MembersList" />
        </div>
    );
};
