import { Button } from '@mui/material';
import * as React from 'react';
import './MypageNiceFromMembersList.css';
import { useNavigate } from 'react-router-dom';
import { FooterIcons } from '../Footer/FooterIcons/FooterIcons';
import test_A_search_user_image from '../Images/MembersList/25102258_s.jpg';
import test_B_search_user_image from '../Images/MembersList/25116697_s.jpg';
import test_C_search_user_image from '../Images/MembersList/25075585_s.jpg';
import test_D_search_user_image from '../Images/MembersList/24656762_s.jpg';

export const MypageNiceFromMembersList = () => {
    const navigate = useNavigate();
    const member_list_items = [
        {
            nick_name: '電算 太郎',
            last_login_date: '11月15日 10時10分',
            image: test_A_search_user_image,
            nice_count: '20',
            self_introduction: 'はじめまして、電算 太郎です。趣味は～',
        },
        {
            nick_name: '電算 花子',
            last_login_date: '11月15日 10時10分',
            image: test_B_search_user_image,
            nice_count: '20',
            self_introduction: 'はじめまして、電算 花子です。趣味は～',
        },
        {
            nick_name: '電算 TEST',
            last_login_date: '11月15日 10時10分',
            image: test_C_search_user_image,
            nice_count: '20',
            self_introduction: 'はじめまして、電算 TESTです。趣味は～',
        },
        {
            nick_name: '電算 太郎',
            last_login_date: '11月15日 10時10分',
            image: test_D_search_user_image,
            nice_count: '20',
            self_introduction: 'はじめまして、電算 太郎です。趣味は～',
        },
    ];
    const members_list: any = [];
    for (let i = 0; i < member_list_items.length; i++) {
        members_list.push(
            <div
                className="members_list"
                onClick={() =>
                    navigate('/MypageNiceApproval', {
                        state: { image: member_list_items[i].image },
                    })
                }
            >
                <div className="last_login">
                    <div>最終ログイン日時：</div>
                    <div>{member_list_items[i].last_login_date}</div>
                </div>

                <div className="members_list_image_nickname_nice">
                    <div className="members_list_nickname_nice">
                        <div>{member_list_items[i].nick_name}</div>
                        <div className="member_list_nice_count">
                            <div>いいね！</div>
                            <div>{member_list_items[i].nice_count}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <img
                        className="search_user_image"
                        src={member_list_items[i].image}
                    />
                </div>
                <div className="members_list_self-introduction_box">
                    {member_list_items[i].self_introduction}
                </div>
            </div>
        );
    }
    const switching_color_button = (
        left: string,
        right: string,
        status: number
    ) => {
        let left_tab = document.getElementById(left);
        let right_tab = document.getElementById(right);
        if (right_tab !== null && left_tab !== null && status == 0) {
            left_tab.className = 'new_nice_tab';
            right_tab.className = 'total_nice_tab';
        }

        if (right_tab !== null && left_tab !== null && status == 1) {
            left_tab.className = 'total_nice_tab';
            right_tab.className = 'new_nice_tab';
        }
    };
    return (
        <div>
            <div className="nice_list_title">いいねリスト</div>
            <div>
                <div className="nice_list_tab">
                    <div
                        id="left"
                        className="new_nice_tab"
                        onClick={() => {
                            switching_color_button('left', 'right', 0);
                        }}
                    >
                        新しい いいね
                    </div>
                    <div
                        className="total_nice_tab"
                        id="right"
                        onClick={() => {
                            switching_color_button('left', 'right', 1);
                        }}
                    >
                        トータルいいね
                    </div>
                </div>
                <div className="nice_counters">
                    <div className="nice_counter">
                        <div>新しい いいね</div>
                        <div>99人</div>
                    </div>
                    <div className="nice_counter">
                        <div>トータル いいね</div>
                        <div>99人</div>
                    </div>
                </div>
            </div>
            <div className="up_message_screen">
                <div onClick={() => navigate('/MypageNiceApproval')}>
                    {members_list}
                </div>
            </div>
            <FooterIcons />
        </div>
    );
};
