import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from '@mui/material';
import * as React from 'react';
import './AgeSelectionScreen.css';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { FooterDecisionClearBotton } from '../Footer/FooterIcons/FooterDecisionClearBotton';
let count = 0;
export const AgeSelectionScreen = () => {
    const navigate = useNavigate();
    const [row_select_age, set_row_selected_age] = useState('指定しない');
    const [hight_select_age, set_hight_selected_age] = useState('指定しない');
    const select_age_ary: string[] = [
        '指定しない',
        '20代未満（18、19）',
        '20代前半',
        '20代後半',
        '30代前半',
        '30代後半',
        '40代前半',
        '40代後半',
        '50代前半',
        '50代後半',
        '60代前半',
        '60代後半',
        '70代前半',
        '70代後半',
        '80代前半',
        '80代後半',
        '90代前半',
        '90代後半',
    ];
    const row_handle_age_change = (event: any) => {
        set_row_selected_age(event.target.value);
    };

    const hight_handle_age_change = (event: any) => {
        set_hight_selected_age(event.target.value);
    };
    return (
        <div>
            <div className="age_select_title">年齢選択</div>

            <div className="age_select_boxes">
                {(function () {
                    const list = [];
                    for (let i = 0; i <= 17; i++) {
                        list.push(
                            <MenuItem value={select_age_ary[i]}>
                                {select_age_ary[i]}
                            </MenuItem>
                        );
                    }
                    return (
                        <FormControl fullWidth>
                            <InputLabel id="">年齢下限</InputLabel>
                            <Select
                                labelId=""
                                id=""
                                value={row_select_age}
                                label="Age"
                                onChange={row_handle_age_change}
                            >
                                {list}
                            </Select>
                        </FormControl>
                    );
                })()}
                <div className="age_font">歳</div>
                <div className="age_font">～</div>
                {(function () {
                    const list = [];
                    for (let i = 0; i <= 17; i++) {
                        list.push(
                            <MenuItem value={select_age_ary[i]}>
                                {select_age_ary[i]}
                            </MenuItem>
                        );
                    }

                    return (
                        <FormControl fullWidth>
                            <InputLabel id="">年齢上限</InputLabel>
                            <Select
                                labelId=""
                                id=""
                                value={hight_select_age}
                                label="Age"
                                onChange={hight_handle_age_change}
                            >
                                {list}
                            </Select>
                        </FormControl>
                    );
                })()}
                <div className="age_font">歳</div>
            </div>
            <FooterDecisionClearBotton
                message="年齢を決定する"
                path="/ConditionSpecification"
            />
        </div>
    );
};
