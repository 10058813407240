import { Button, Stack, TextField } from '@mui/material';
import * as React from 'react';
import './InterestedItemEdit.css';
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from 'react';

let id_counter = 0;
let add_candidate: string[] = [];

export const InterestedItemEdit = () => {
    const navigate = useNavigate();
    const [message_list, setList] = useState([
        <div style={{ listStyle: 'none' }}>{''}</div>,
    ]);

    const candidate_ary = [
        '音楽',
        '映画',
        '芸能・テレビ',
        'ゲーム',
        '本・漫画',
        'アート',
        'スポーツ',
        '車・バイク',
        '旅行',
        'ホーム・DIY',
        '動物・ペット',
        'PC・インターネット',
        'ファッション',
        'グルメ・お酒',
        '占い',
        '趣味全般',
        '恋愛・結婚',
        '心・体',
        '生活',
        '美容・健康',
        '家事・育児',
        '地域・国',
        '会社・団体',
        '職業・資格',
        '研究・学問',
        'ビジネス・経済',
    ];

    const addClick = (str: string, index: number) => {
        if (add_candidate.indexOf(str) == -1) {
            add_candidate.push(str);
            const newList: JSX.Element[] = [
                ...message_list,

                <li className="parent" id={String(index)}>
                    {str}
                    <ClearIcon
                        className="clear_icon"
                        onClick={() => remove(index, str)}
                    />
                </li>,
            ];
            id_counter = id_counter + 1;
            setList(newList);
        }
    };

    const remove = (index: number, str: string) => {
        const ul = document.getElementById('selected_items');

        const li = ul?.children;

        if (li !== undefined) {
            for (let i = 0; i < li.length - 1; i++) {
                if (index === Number(li[i + 1].id)) {
                    li[i + 1].remove();
                    add_candidate = add_candidate.filter(
                        (item) => item !== str
                    );
                }
            }
        }
    };
    return (
        <div>
            <div className="interested_item_edit_title">興味ある事</div>
            <div className="selected_value">
                <div className="selected_value_title">選択済み</div>
                <ul id="selected_items">{message_list}</ul>
            </div>

            <div className="selection_candidate">
                <div className="selection_candidate_title">選択候補</div>
                <TextField
                    className="selection_candidate_serch_box"
                    style={{ marginLeft: '15px' }}
                    id="outlined-basic"
                    label="検索"
                    variant="outlined"
                />

                <ul className="items_of_interest">
                    {candidate_ary.map((candidate_ary, index) => (
                        <li
                            id="items_of_interest"
                            key={index}
                            onClick={() => addClick(candidate_ary, index)}
                        >
                            {candidate_ary}
                        </li>
                    ))}
                </ul>
            </div>
            <Stack direction="row" spacing={2}>
                <Button
                    variant="contained"
                    style={{
                        width: '100%',
                        padding: '2%',
                        borderRadius: '50px',
                        color: 'white',
                        fontSize: '100%',
                        marginTop: '5%',
                    }}
                    sx={{ backgroundColor: '#99CCFF', p: 2 }}
                    onClick={() => navigate('')}
                >
                    興味ある事を設定する
                </Button>
            </Stack>
        </div>
    );
};
