import * as React from 'react';
import './MatchingMembersList.css';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import { FooterIcons } from '../Footer/FooterIcons/FooterIcons';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MatchingListTop } from '../MatchingListTop/MatchingListTop';
import test_A_search_user_image from '../Images/MatchingMembersList/24638138_s.jpg';
import test_B_search_user_image from '../Images/MatchingMembersList/25027115_s.jpg';
import test_C_search_user_image from '../Images/MatchingMembersList/25220045_s.jpg';
import test_D_search_user_image from '../Images/MatchingMembersList/24656762_s.jpg';

export const MatchingMembersList = () => {
    const member_list_items = [
        {
            nick_name: '電算 太郎',
            last_login_date: '11月15日 10時10分',
            image: test_A_search_user_image,
            nice_count: '20',
            self_introduction: 'はじめまして、電算 太郎です。趣味は～',
        },
        {
            nick_name: '電算 花子',
            last_login_date: '11月15日 10時10分',
            image: test_B_search_user_image,
            nice_count: '20',
            self_introduction: 'はじめまして、電算 花子です。趣味は～',
        },
        {
            nick_name: '電算 TEST',
            last_login_date: '11月15日 10時10分',
            image: test_C_search_user_image,
            nice_count: '20',
            self_introduction: 'はじめまして、電算 TESTです。趣味は～',
        },
        {
            nick_name: '電算 太郎',
            last_login_date: '11月15日 10時10分',
            image: test_D_search_user_image,
            nice_count: '20',
            self_introduction: 'はじめまして、電算 太郎です。趣味は～',
        },
    ];
    const members_list: any = [];
    for (let i = 0; i < member_list_items.length; i++) {
        members_list.push(
            <div
                className="members_list"
                onClick={() => navigate('/SearchMemberProfile')}
            >
                <div className="last_login">
                    <div>最終ログイン日時：</div>
                    <div>{member_list_items[i].last_login_date}</div>
                </div>
                <div className="members_list_image_nickname_nice">
                    <div className="members_list_nickname_nice">
                        <div>{member_list_items[i].nick_name}</div>
                        <div className="member_list_nice_count">
                            <div>いいね！</div>
                            <div>{member_list_items[i].nice_count}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <img
                        className="search_user_image"
                        src={member_list_items[i].image}
                    />
                </div>
                <div className="members_list_self-introduction_box">
                    {member_list_items[i].self_introduction}
                </div>
            </div>
        );
    }
    const navigate = useNavigate();
    return (
        <div>
            <div className="matching_member_list_title">マッチングリスト</div>
            <MatchingListTop path="/MatchingMembersList" />
            <div className="content_common">
                <div
                    className="matching_list_matching_lists"
                    onClick={() =>
                        navigate('/MatchingMemberProfile', {
                            state: { path: '/MatchingMembersList' },
                        })
                    }
                >
                    {members_list}
                </div>
            </div>

            <FooterIcons path="/MatchingMembersList" />
        </div>
    );
};
