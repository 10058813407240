import { Checkbox } from '@mui/material';
import './ResidenceSelectionScreen.css';
import './ResidenceSelectionScreen.css';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { FooterDecisionClearBotton } from '../Footer/FooterIcons/FooterDecisionClearBotton';

export const ResidenceSelectionScreen = () => {
    const navigate = useNavigate();
    const [select_prefecture, set_prefectures] = useState(['']);

    const prefectures_items: string[] = [
        '北海道',
        '青森',
        '岩手',
        '宮城',
        '秋田',
        '山形',
        '福島',
        '茨城',
        '栃木',
        '群馬',
        '埼玉',
        '千葉',
        '東京',
        '神奈川',
        '新潟',
        '富山',
        '石川',
        '福井',
        '山梨',
        '長野',
        '岐阜',
        '静岡',
        '愛知',
        '三重',
        '滋賀',
        '京都',
        '大阪',
        '兵庫',
        '奈良',
        '和歌山',
        '鳥取',
        '島根',
        '岡山',
        '広島',
        '山口',
        '徳島',
        '香川',
        '愛媛',
        '高知',
        '福岡',
        '佐賀',
        '長崎',
        '熊本',
        '大分',
        '宮崎',
        '鹿児島',
        '沖縄',
    ];
    const add_check_prefectures_item = (index: any, prefectures_items: any) => {
        set_prefectures([...select_prefecture, prefectures_items]);
    };

    return (
        <div>
            <div className="check_box_specification_title">居住地</div>
            <div className="main_content_common">
                <ul className="prefectures_items">
                    {prefectures_items.map((prefectures_items, index) => (
                        <li>
                            <p>{prefectures_items}</p>
                            <Checkbox
                                sx={{
                                    '& .MuiSvgIcon-root': { fontSize: 28 },
                                }}
                                onChange={(event) =>
                                    add_check_prefectures_item(
                                        index,
                                        prefectures_items
                                    )
                                }
                            />
                        </li>
                    ))}
                </ul>
            </div>

            <FooterDecisionClearBotton
                message="居住地を決定する"
                path="/ConditionSpecification"
            />
        </div>
    );
};
