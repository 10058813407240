import * as React from 'react';
import './ServiceRegistration.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { FooterDecisionBotton } from '../Footer/FooterIcons/FooterDecisionBotton';

export const ServiceRegistration = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div className="service_registration_title">Uマッチング登録</div>
            <div className="main_content_common">
                <div className="service_registration_box">
                    <div>ニックネーム</div>
                    <input type="text" placeholder="任意項目" />
                </div>
                <div
                    className="service_registration_box"
                    style={{ marginTop: '10%' }}
                    onClick={() =>
                        navigate('/ExerciseMethodSelect', {
                            state: { path: '/ServiceRegistration' },
                        })
                    }
                >
                    <div>マッチングで活用したい事</div>
                    <div style={{ display: 'flex', position: 'relative' }}>
                        <input type="text" placeholder="別画面設定" />
                        <ArrowForwardIosIcon
                            style={{
                                position: 'absolute',
                                top: '40%',
                                left: '92%',
                                color: '#a0a0a0',
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex', position: 'relative' }}>
                        <input type="text" placeholder="別画面設定" />
                        <ArrowForwardIosIcon
                            style={{
                                position: 'absolute',
                                top: '40%',
                                left: '92%',
                                color: '#a0a0a0',
                            }}
                        />
                    </div>
                </div>
                <div
                    className="service_registration"
                    style={{ marginTop: '10%' }}
                >
                    <p>自己紹介</p>
                    <div className="word_counter">XXX/2000文字</div>
                </div>
                <input
                    className="input_box_service_registration"
                    type="text"
                    placeholder="自己紹介文"
                />
                <div
                    className="service_registration_box"
                    style={{ marginTop: '10%' }}
                    onClick={() =>
                        navigate('/InterestingThingsSelect', {
                            state: { path: '/ServiceRegistration' },
                        })
                    }
                >
                    <div>興味あること</div>
                    <div style={{ display: 'flex', position: 'relative' }}>
                        <input type="text" placeholder="別画面設定" />
                        <ArrowForwardIosIcon
                            style={{
                                position: 'absolute',
                                top: '40%',
                                left: '92%',
                                color: '#a0a0a0',
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex', position: 'relative' }}>
                        <input type="text" placeholder="別画面設定" />
                        <ArrowForwardIosIcon
                            style={{
                                position: 'absolute',
                                top: '40%',
                                left: '92%',
                                color: '#a0a0a0',
                            }}
                        />
                    </div>
                </div>
            </div>

            <div>
                <FooterDecisionBotton
                    path="/RegistrationConfirmation"
                    message="次へ"
                />
            </div>
        </div>
    );
};
