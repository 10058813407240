import { Button, Checkbox, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './FooterDecisionBotton.css';

export const FooterDecisionBotton = (props: any) => {
    const navigate = useNavigate();
    const path = props.path;
    const message = props.message;
    return (
        <div className="footer_decision_botton_layoout">
            <Stack direction="row" spacing={2}>
                <Button variant="contained" onClick={() => navigate(path)}>
                    {message}
                </Button>
            </Stack>
        </div>
    );
};
