import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import './MatchingMembersCard.css';
import { FooterIcons } from '../Footer/FooterIcons/FooterIcons';
import { MatchingListTop } from '../MatchingListTop/MatchingListTop';
import test_A_search_user_image from '../Images/MatchingMembersList/24638138_s.jpg';
import test_B_search_user_image from '../Images/MatchingMembersList/25027115_s.jpg';
import test_C_search_user_image from '../Images/MatchingMembersList/25220045_s.jpg';
import test_D_search_user_image from '../Images/MatchingMembersList/24656762_s.jpg';
export const MatchingMembersCard = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div className="matching_member_card_title">マッチングリスト</div>
            <MatchingListTop path="/MatchingMembersCard" />
            <div className="content_common">
                <div
                    className="matching_cards"
                    onClick={() =>
                        navigate('/MatchingMemberProfile', {
                            state: { path: '/MatchingMembersCard' },
                        })
                    }
                >
                    <div className="matching_cards_flex_cards">
                        <div className="one_card">
                            <div>
                                <img
                                    className="matching_cards_image"
                                    src={test_A_search_user_image}
                                    alt=""
                                />
                            </div>
                            <div>
                                <div>電算 太郎</div>
                                <div>居住地</div>
                                <div className="matching_cards_self_introduction">
                                    <p>自己紹介</p>
                                </div>
                            </div>
                        </div>
                        <div className="one_card">
                            <div>
                                <img
                                    className="matching_cards_image"
                                    src={test_B_search_user_image}
                                    alt=""
                                />
                            </div>
                            <div>
                                <div>電算 テスト</div>
                                <div>居住地</div>
                                <div className="matching_cards_self_introduction">
                                    <p>自己紹介</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="matching_cards_flex_cards">
                        <div className="one_card">
                            <div>
                                <img
                                    className="matching_cards_image"
                                    src={test_C_search_user_image}
                                    alt=""
                                />
                            </div>
                            <div>
                                <div>電算 太郎</div>
                                <div>居住地</div>
                                <div className="matching_cards_self_introduction">
                                    <p>自己紹介</p>
                                </div>
                            </div>
                        </div>
                        <div className="one_card">
                            <div>
                                <img
                                    className="matching_cards_image"
                                    src={test_D_search_user_image}
                                    alt=""
                                />
                            </div>
                            <div>
                                <div>電算 テスト</div>
                                <div>居住地</div>
                                <div className="matching_cards_self_introduction">
                                    <p>自己紹介</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterIcons path="/MatchingMembersList" />
        </div>
    );
};
