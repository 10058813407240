import { Button } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export const GenderSelectionScreen = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Button onClick={() => navigate('/ConditionSpecification')}>
                戻る
            </Button>
            <p>性別設定画面</p>
            <p>準備中</p>
        </div>
    );
};
