import { Button } from '@mui/material';
import * as React from 'react';
import './Mypage.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { FooterIcons } from '../Footer/FooterIcons/FooterIcons';
import my_image from '../Images/Mypage/mypage_image.jpg';

export const Mypage = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div>
                <div className="mypage_title">マイページ</div>
                <div className="main_content_common">
                    <div className="mypage_cercle_aria">
                        <div>
                            <img className="my_image" src={my_image} />
                        </div>
                    </div>
                    <div className="mypage_mystatus">
                        <div className="mypage_value">
                            <div>残いいね数</div>
                            <div>100いいね</div>
                        </div>
                        <div className="mypage_value">
                            <div>残ポイント</div>
                            <div>100いいね</div>
                        </div>
                        <div className="mypage_value">
                            <div>プロフィール現在</div>
                            <div>50%</div>
                        </div>
                    </div>
                    <div className="mypage_tile_botton">
                        <div
                            className="mypage_tile_botton_tile_upper_row"
                            onClick={() =>
                                navigate('/MypageMyProfile', {
                                    state: { image: my_image },
                                })
                            }
                        >
                            <p style={{ fontSize: '16px' }}>プロフィール</p>
                        </div>
                        <div className="mypage_tile_botton_tile_upper_row">
                            <p
                                onClick={() =>
                                    navigate('/MypageFootPrintsList')
                                }
                            >
                                あしあと
                            </p>
                        </div>
                        <div className="mypage_tile_botton_tile_upper_row">
                            <p
                                onClick={() =>
                                    navigate('/MypageNiceFromMembersList')
                                }
                            >
                                いいね
                            </p>
                        </div>
                        <div className="mypage_tile_botton_tile_lower_row">
                            <p>ボタン</p>
                        </div>
                        <div className="mypage_tile_botton_tile_lower_row">
                            <p>ボタン</p>
                        </div>
                        <div className="mypage_tile_botton_tile_lower_row">
                            <p>ボタン</p>
                        </div>
                    </div>
                </div>
                <FooterIcons />
            </div>
        </div>
    );
};
