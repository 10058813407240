import { Button, Checkbox, Stack } from '@mui/material';
import './MembershipRegistrationAgreement.css';
import { useNavigate } from 'react-router-dom';
import { FooterDecisionBotton } from '../Footer/FooterIcons/FooterDecisionBotton';

export const MembershipRegistrationAgreement = () => {
    const navigate = useNavigate();
    return (
        <div className="wrapper">
            <div className="main">
                <div className="mobile_membership_registration_agreement_title">
                    利用規約
                </div>

                <div className="main_content_common">
                    <div className="mobile_terms_of_service_box">
                        <p>ここに規約内容</p>
                    </div>
                    <div className="terms_of_service_check">
                        <div>
                            <Checkbox />
                        </div>
                        <p>利用規約に同意する</p>
                    </div>
                    <FooterDecisionBotton
                        path="/ServiceRegistration"
                        message="次へ"
                    />
                </div>
            </div>
        </div>
    );
};
