import { Button, Stack } from '@mui/material';
import * as React from 'react';
import './MypageNiceApproval.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { FooterIcons } from '../Footer/FooterIcons/FooterIcons';

export const MypageNiceApproval = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const receive_image = location.state.image;

    return (
        <div className="common_nice_member_profile">
            <div className="nice_member_profile_titile">プロフィール</div>
            <div className="main_content_common">
                <div className="nice_member_profile_imag">
                    <div className="nice_member_profile_image_cercle">
                        <p>画像</p>
                    </div>
                </div>
                <div className="common_font_size">
                    <div className="nice_profile_nickname">
                        ニックネーム
                        <div>お相手のなまえ</div>
                    </div>
                    <div className="nice_profile_exercise_method">
                        マッチングで活用したい事
                        <div>例：婚活</div>
                        <div>例：相談・コンサル（専門家）</div>
                    </div>
                    <div className="nice_member_self_introduction">
                        自己紹介
                        <div>自己紹介文</div>
                    </div>
                    {/* ここから興味ある事 */}
                    <div className="nice_member_interesting_things">
                        <p>興味あること</p>
                        <div className="nice_member_interesting_detail">
                            <p>
                                <Button
                                    style={{
                                        borderRadius: '20px',
                                        border: 'solid 1px',
                                        color: '#666',
                                        width: '30%',
                                    }}
                                    variant="outlined"
                                >
                                    DIY
                                </Button>
                                <Button
                                    style={{
                                        borderRadius: '20px',
                                        border: 'solid 1px',
                                        color: '#666',
                                        marginLeft: '5%',
                                        width: '50%',
                                    }}
                                    variant="outlined"
                                >
                                    ボランティア
                                </Button>
                            </p>
                            <Button
                                style={{
                                    borderRadius: '20px',
                                    border: 'solid 1px',
                                    color: '#666',
                                    width: '30%',
                                }}
                                variant="outlined"
                            >
                                ジム
                            </Button>
                        </div>
                    </div>
                    {/* ここまで興味ある事 */}
                    {/* ここからその他設定項目 */}
                    <div className="mypage_nice_other_setting_items">
                        <div>
                            年齢
                            <div className="mypage_nice_other_setting_item">
                                年齢
                            </div>
                        </div>
                        <div>
                            性別
                            <div className="mypage_nice_other_setting_item">
                                性別
                            </div>
                        </div>
                        <div>
                            職業
                            <div className="mypage_nice_other_setting_item">
                                職業
                            </div>
                        </div>
                        <div>
                            職歴
                            <div className="mypage_nice_other_setting_item">
                                職歴
                            </div>
                        </div>
                        <div>
                            学歴
                            <div className="mypage_nice_other_setting_item">
                                学歴
                            </div>
                        </div>
                        <div>
                            居住地
                            <div className="mypage_nice_other_setting_item">
                                居住地
                            </div>
                        </div>
                        <div>
                            出身地
                            <div className="mypage_nice_other_setting_item">
                                出身地
                            </div>
                        </div>
                        <div>
                            交際ステータス
                            <div className="mypage_nice_other_setting_item">
                                交際ステータス
                            </div>
                        </div>
                        <div>
                            身長
                            <div className="mypage_nice_other_setting_item">
                                身長
                            </div>
                        </div>
                        <div>
                            体重
                            <div className="mypage_nice_other_setting_item">
                                体重
                            </div>
                        </div>
                        <div>
                            年収
                            <div className="mypage_nice_other_setting_item">
                                年収
                            </div>
                        </div>
                        <div>
                            性格
                            <div className="mypage_nice_other_setting_item">
                                性格
                            </div>
                        </div>
                    </div>
                </div>
                {/* ここまでその他設定項目 */}
                <Stack direction="row" spacing={2}>
                    <Button
                        variant="contained"
                        style={{
                            width: '100%',
                            height: '50px',
                            marginTop: '3%',
                            padding: '2%',
                            borderRadius: '50px',
                            color: 'white',
                            fontSize: '150%',
                        }}
                        sx={{ backgroundColor: '#99CCFF', p: 2 }}
                        onClick={() => navigate('/MatchingReturnMessage')}
                    >
                        いいねを返す
                    </Button>
                </Stack>
            </div>
            <FooterIcons />
        </div>
    );
};
