import { Button, Checkbox, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { FooterDecisionBotton } from '../Footer/FooterIcons/FooterDecisionBotton';
import './ExerciseMethodSelect.css';

export const ExerciseMethodSelect = () => {
    const location = useLocation();
    const path = location.state;

    return (
        <div>
            <div className="exerciseMethodSelect_title">活用したい事</div>
            <div className="main_content_common">
                <div className="exerciseMethodSelect_datail">
                    <p>ビジネスパートナー（提携）</p>
                    <p>仕事探し（アルバイト・転職）</p>
                    <p>求人（雇用）</p>
                    <p>婚活</p>
                    <p>友達</p>
                    <p>サークル・趣味</p>
                    <p>相談・コンサル（専門家）</p>
                </div>
                <FooterDecisionBotton
                    path={path.path}
                    message="活用したい事を決定する"
                />
            </div>
        </div>
    );
};
