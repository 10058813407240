import { Checkbox } from '@mui/material';
import './FooterIcons.css';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import SummarizeIcon from '@mui/icons-material/Summarize';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
/**
 * フッターアイコン共通コンポーネント化します。
 */
export const FooterIcons = (props: any) => {
    const navigate = useNavigate();
    return (
        <div>
            <div className="mobile_fotter_icons">
                <div className="mobile_search_icon">
                    <SearchIcon
                        style={{
                            fontSize: '50px',
                            marginLeft: '20%',
                            color: '#CCC',
                        }}
                        onClick={() => navigate('/ConditionSpecification')}
                    />
                    探す
                </div>

                <div className="mobile_matching_list_icon">
                    <SummarizeIcon
                        style={{
                            fontSize: '50px',
                            marginLeft: '20%',
                            color: '#CCC',
                        }}
                        onClick={() => navigate('/MatchingMembersList')}
                    />
                    マッチングリスト
                </div>
                <div className="mobile_message_icon">
                    <EmailIcon
                        style={{
                            fontSize: '50px',
                            marginLeft: '20%',
                            color: '#CCC',
                        }}
                        onClick={() =>
                            navigate('/MessageList', {
                                state: { path: props.path },
                                replace: false,
                            })
                        }
                    />
                    メッセージ
                </div>
                <div className="mobile_maypage_icon">
                    <PersonIcon
                        style={{
                            fontSize: '50px',
                            marginLeft: '20%',
                            color: '#CCC',
                        }}
                        onClick={() =>
                            navigate('/Mypage', {
                                state: { path: props.path },
                                replace: false,
                            })
                        }
                    />
                    マイページ
                </div>
            </div>
        </div>
    );
};
