import { Button, Stack } from '@mui/material';
import * as React from 'react';
import './MatchingMemberProfile.css';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackButton } from '../BackButton/BackButton';
import { FooterIcons } from '../Footer/FooterIcons/FooterIcons';

export const MatchingMemberProfile = () => {
    const location = useLocation();
    const path: string = location.state.path;
    const navigate = useNavigate();
    return (
        <div style={{ color: '#666' }}>
            <div className="serch_member_profile_title">
                <div>プロフィール</div>
            </div>
            <div className="main_content_common">
                <div className="serch_member_profile_image">
                    <div className="serch_member_profile_image_cercle">
                        <p>画像</p>
                    </div>
                    <div className="onlain_status">
                        <div>
                            <Brightness1Icon style={{ color: '#99FF99' }} />
                        </div>
                        <div>オンライン</div>
                    </div>
                    <div className="onlain_status">
                        <div>
                            <Brightness1Icon style={{ color: 'FFFF00' }} />
                        </div>
                        <div>数時間以内</div>
                    </div>
                    <div
                        className="onlain_status"
                        style={{ marginLeft: '17px' }}
                    >
                        <div>
                            <Brightness1Icon style={{ color: 'FF6666' }} />
                        </div>
                        <div>２４時間以上前</div>
                    </div>
                </div>
                <div className="serch_member_profile_font_style">
                    ニックネーム
                    <input type="text" placeholder="お相手のニックネーム" />
                </div>
                <div className="serch_member_profile_font_style">
                    マッチングで活用したい事
                    <div>
                        <input
                            className="serch_member_profile_font_style"
                            type="text"
                            placeholder="婚活"
                        />
                        <input
                            className="serch_member_profile_font_style"
                            type="text"
                            placeholder="相談・コンサル（専門家）"
                        />
                    </div>
                </div>
                <div className="serch_member_self_introduction input">
                    自己紹介
                    <input type="text" placeholder="自己紹介文" />
                </div>
                <div className="serch_member_profile_font_style">
                    <p>興味あること</p>
                    <div className="serch_member_interesting_detail">
                        <p>
                            <Button
                                style={{
                                    borderRadius: '20px',
                                    border: 'solid 1px',
                                    color: '#666',
                                    width: '30%',
                                }}
                                variant="outlined"
                            >
                                DIY
                            </Button>
                            <Button
                                style={{
                                    borderRadius: '20px',
                                    border: 'solid 1px',
                                    color: '#666',
                                    marginLeft: '5%',
                                    width: '50%',
                                }}
                                variant="outlined"
                            >
                                ボランティア
                            </Button>
                        </p>
                        <Button
                            style={{
                                borderRadius: '20px',
                                border: 'solid 1px',
                                color: '#666',
                                width: '30%',
                            }}
                            variant="outlined"
                        >
                            ジム
                        </Button>
                    </div>
                </div>
                <div className="serch_member_profile_font_style">
                    <div className="serch_member_profile_font_margin">
                        年齢
                        <input type="text" placeholder="お相手の年齢" />
                    </div>
                    <div className="serch_member_profile_font_margin">
                        性別
                        <input type="text" placeholder="お相手の性別" />
                    </div>
                    <div className="serch_member_profile_font_margin">
                        職業
                        <input type="text" placeholder="お相手の職業" />
                    </div>
                    <div className="serch_member_profile_font_margin">
                        職歴
                        <input type="text" placeholder="お相手の職歴" />
                    </div>
                    <div className="serch_member_profile_font_margin">
                        学歴
                        <input type="text" placeholder="お相手の学歴" />
                    </div>
                    <div className="serch_member_profile_font_style">
                        居住地
                        <input type="text" placeholder="お相手の居住地" />
                    </div>
                    <div className="serch_member_profile_font_margin">
                        出身地
                        <input type="text" placeholder="お相手の出身地" />
                    </div>
                    <div className="serch_member_profile_font_margin">
                        交際ステータス
                        <input
                            type="text"
                            placeholder="お相手の交際ステータス"
                        />
                    </div>
                    <div className="serch_member_profile_font_margin">
                        身長
                        <input type="text" placeholder="お相手の身長" />
                    </div>
                    <div className="serch_member_profile_font_margin">
                        体重
                        <input type="text" placeholder="お相手の体重" />
                    </div>
                    <div className="serch_member_profile_font_margin">
                        年収
                        <input type="text" placeholder="お相手の年収" />
                    </div>
                    <div className="serch_member_profile_font_margin">
                        性格
                        <input type="text" placeholder="お相手の性格" />
                    </div>
                </div>
                <Stack direction="row" spacing={2}>
                    <Button
                        variant="contained"
                        style={{
                            width: '100%',
                            height: '50px',
                            marginTop: '10px',
                            padding: '2%',
                            borderRadius: '50px',
                            color: 'white',
                            fontSize: '150%',
                        }}
                        sx={{ backgroundColor: '#99CCFF', p: 2 }}
                        onClick={() =>
                            navigate('/MessageExchange', {
                                state: { path: '/MatchingMemberProfile' },
                                replace: false,
                            })
                        }
                    >
                        メッセージを始める
                    </Button>
                </Stack>
            </div>

            <FooterIcons />
        </div>
    );
};
