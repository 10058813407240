import { Button, Stack } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FooterIcons } from '../Footer/FooterIcons/FooterIcons';
import './MatchingReturnMessage.css';

export const MatchingReturnMessage = () => {
    const navigate = useNavigate();
    const [message, set_message] = useState('');
    const [count, set_count] = useState(0);
    const change_message = (event: any) => {
        set_message(event.target.value);
        set_count(event.target.value.length);
    };
    function popup_message() {
        alert('テストメッセージ：メッセージ内容を送信しました。');
    }
    /**
     * テキストエリア文字数カウント
     * 改行の文字数は文字数にカウントしない
     */
    const change_count = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let textarea_str: any = event.target.value;
        let line_feed_code: string[] = textarea_str.match(/\r\n|\n/g);
        let line_feed_code_count = 0;
        if (line_feed_code !== null) {
            line_feed_code_count = line_feed_code.length;
        }
        set_message(event.target.value);
        set_count(event.target.value.length - line_feed_code_count);
    };
    useEffect(() => {
        if (message.length === 2000) {
            alert('最大文字数になりました。');
        }
    });
    return (
        <div>
            <div className="return_message_title">マッチングメッセージ</div>
            <div className="main_content_common">
                <div className="fixed_phrase_index">定型文を使用する</div>
                <select
                    className="fixed_phrase_select"
                    name=""
                    id=""
                    onChange={(event) => change_message(event)}
                >
                    <option
                        value=""
                        disabled
                        selected
                        style={{ display: 'none' }}
                    >
                        定型文を選択
                    </option>
                    <option value="いいねありがとうございます。">
                        いいねありがとうございます。
                    </option>
                    <option value="こちらこそよろしくお願いします。">
                        こちらこそよろしくお願いします。
                    </option>
                    <option value="プロフィールが気に入りました。">
                        プロフィールが気に入りました。
                    </option>
                </select>
                <div className="return_message_top">
                    <div style={{ fontSize: '20px' }}>メッセージを入力する</div>
                    <div>{count}/2000文字</div>
                </div>
                <div>
                    <textarea
                        className="return_message_box"
                        id="textarea"
                        cols={33}
                        rows={10}
                        value={message}
                        maxLength={2000}
                        onChange={(event) => {
                            change_count(event);
                        }}
                    ></textarea>
                </div>
                <Stack direction="row" spacing={2}>
                    <Button
                        variant="contained"
                        style={{
                            width: '100%',
                            height: '50px',
                            marginTop: '10px',
                            padding: '2%',
                            borderRadius: '50px',
                            color: 'white',
                            fontSize: '150%',
                        }}
                        sx={{ backgroundColor: '#99CCFF', p: 2 }}
                        onClick={() => popup_message()}
                    >
                        この内容で送信する
                    </Button>
                </Stack>
            </div>
            <FooterIcons />
        </div>
    );
};
