import { Button, Stack } from '@mui/material';
import './InterestingThingsSelect.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { FooterDecisionBotton } from '../Footer/FooterIcons/FooterDecisionBotton';

export const InterestingThingsSelect = () => {
    const location = useLocation();
    const path = location.state;
    return (
        <div>
            <div className="InterestingThingsSelect_title">
                興味ある事（カテゴリー別）
            </div>
            <div className="main_content_common">
                <div className="InterestingThingsSelect_datail">
                    <p>音楽</p>
                    <p>映画</p>
                    <p>芸能・テレビ</p>
                    <p>ゲーム</p>
                    <p>本・漫画</p>
                    <p>アート</p>
                    <p>スポーツ</p>
                    <p>車・バイク</p>
                    <p>旅行</p>
                    <p>ホーム・DIY</p>
                    <p>動物・ペット</p>
                    <p>PC・インターネット</p>
                    <p>ファッション</p>
                    <p>グルメ・お酒</p>
                    <p>占い</p>
                    <p>趣味全般</p>
                    <p>恋愛・結婚</p>
                    <p>心・体</p>
                    <p>生活</p>
                    <p>美容・健康</p>
                    <p>家事・育児</p>
                    <p>地域・国</p>
                    <p>会社・団体</p>
                    <p>職業・資格</p>
                    <p>研究・学問</p>
                    <p>ビジネス・経済</p>
                </div>
            </div>
            <FooterDecisionBotton
                path={path.path}
                message="興味ある事を決定する"
            />
        </div>
    );
};
