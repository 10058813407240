import { Button, Stack } from '@mui/material';
import * as React from 'react';
import './MypageMyProfile.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { FooterIcons } from '../Footer/FooterIcons/FooterIcons';

export const MypageMyProfile = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const receive_image = location.state.image;
    return (
        <div className="common_search_member_profile">
            <div className="search_member_profile_titile">マイプロフィール</div>
            <div className="main_content_common">
                <div className="search_member_profile_imag">
                    <div className="search_member_profile_image_cercle">
                        <p>画像</p>
                    </div>
                </div>
                <div className="common_font_size">
                    <div className="search_member_profile_nickname">
                        ニックネーム
                        <input type="text" placeholder="お相手のニックネーム" />
                    </div>
                    <div className="my_profile_exercise_method">
                        マッチングで活用したい事
                        <input type="text" placeholder="例：婚活" />
                        <input
                            type="text"
                            placeholder="例：相談・コンサル（専門家）"
                        />
                    </div>
                    <div className="my_profile_self_introduction">
                        自己紹介
                        <input type="text" placeholder="自己紹介文" />
                    </div>
                    {/* ここから興味ある事 */}
                    <div>
                        <p>興味あること</p>
                        <div className="my_profile_interesting_detail">
                            <p>
                                <Button
                                    style={{
                                        borderRadius: '20px',
                                        border: 'solid 1px',
                                        color: '#666',
                                        width: '30%',
                                    }}
                                    variant="outlined"
                                >
                                    DIY
                                </Button>
                                <Button
                                    style={{
                                        borderRadius: '20px',
                                        border: 'solid 1px',
                                        color: '#666',
                                        marginLeft: '5%',
                                        width: '50%',
                                    }}
                                    variant="outlined"
                                >
                                    ボランティア
                                </Button>
                            </p>
                            <Button
                                style={{
                                    borderRadius: '20px',
                                    border: 'solid 1px',
                                    color: '#666',
                                    width: '30%',
                                }}
                                variant="outlined"
                            >
                                ジム
                            </Button>
                        </div>
                    </div>
                    {/* ここまで興味ある事 */}
                    {/* ここからその他設定項目 */}
                    <div className="my_profile_setting_items">
                        <div>
                            年齢
                            <input type="text" placeholder="年齢" />
                        </div>
                        <div>
                            性別
                            <input type="text" placeholder="性別" />
                        </div>
                        <div>
                            職業
                            <input type="text" placeholder="職業" />
                        </div>
                        <div>
                            職歴
                            <input type="text" placeholder="職歴" />
                        </div>
                        <div>
                            学歴
                            <input type="text" placeholder="学歴" />
                        </div>
                        <div>
                            居住地
                            <input type="text" placeholder="居住地" />
                        </div>
                        <div>
                            出身地
                            <input type="text" placeholder="出身地" />
                        </div>
                        <div>
                            交際ステータス
                            <input type="text" placeholder="交際ステータス" />
                        </div>
                        <div>
                            身長
                            <input type="text" placeholder="身長" />
                        </div>
                        <div>
                            体重
                            <input type="text" placeholder="体重" />
                        </div>
                        <div>
                            年収
                            <input type="text" placeholder="年収" />
                        </div>
                        <div>
                            性格
                            <input type="text" placeholder="性格" />
                        </div>
                    </div>
                </div>
                {/* ここまでその他設定項目 */}
            </div>

            <FooterIcons />
        </div>
    );
};
