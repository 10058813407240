import { Button, Checkbox, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './FooterDecisionClearBotton.css';
import SearchIcon from '@mui/icons-material/Search';
export const FooterDecisionClearBotton = (props: any) => {
    const navigate = useNavigate();
    const path = props.path;
    const message = props.message;
    const icontype = props.icontype;

    return (
        <div>
            <div
                className="footer_ddecision_botton_layoout"
                onClick={() => navigate(path)}
            >
                <Stack direction="row" spacing={2}>
                    <Button
                        variant="contained"
                        startIcon={<SearchIcon style={{ fontSize: '178%' }} />}
                    >
                        {message}
                    </Button>
                </Stack>
            </div>
            <div className="footer_cclear_botton_layoout">
                <Stack direction="row" spacing={2}>
                    <Button variant="contained">クリアする</Button>
                </Stack>
            </div>
        </div>
    );
};
