import * as React from 'react';
import './RegistrationConfirmation.css';
import '../Content.css';
import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FooterDecisionBotton } from '../Footer/FooterIcons/FooterDecisionBotton';

export const RegistrationConfirmation = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div className="mobile_confirm_registration_details_title">
                登録内容確認
            </div>
            <div className="main_content_common">
                <div className="mobile_confirm_registration_details_message">
                    <p>こちらの内容で登録してもよろしいでしょうか？</p>
                </div>
                <div className="registration_confirmation_nick_name">
                    <div>ニックネーム</div>
                    <div className="registration_confirmation_nick_name_box">
                        前画面で入力した内容
                    </div>
                </div>
                <div className="registration_exercise_method">
                    <div>マッチングで活用したい事</div>
                    <div className="registration_exercise_method_box">
                        前画面で入力した内容
                    </div>
                    <div className="registration_exercise_method_box">
                        前画面で入力した内容
                    </div>
                </div>
                <div className="registration_self-introduction">
                    <div>自己紹介文</div>
                    <div className="registration_self-introduction_box">
                        <p>全画面で入力した自己紹介文</p>
                    </div>
                </div>
                <p
                    style={{
                        marginTop: '10%',
                        color: '#666',
                        fontSize: '20px',
                    }}
                >
                    興味あること
                </p>
                <div className="mobile_interesting_things">
                    <Button
                        style={{
                            borderRadius: '20px',
                            border: 'solid 1px',
                            color: '#666',
                        }}
                        className="interested_button"
                        variant="outlined"
                    >
                        ガーデニング
                    </Button>
                    <Button
                        style={{
                            marginLeft: '20px',
                            borderRadius: '20px',
                            border: 'solid 1px',
                            color: '#666',
                        }}
                        className="interested_button"
                        variant="outlined"
                    >
                        ファッション
                    </Button>
                    <Button
                        style={{
                            marginTop: '20px',
                            borderRadius: '20px',
                            border: 'solid 1px',
                            color: '#666',
                        }}
                        className="interested_button"
                        variant="outlined"
                    >
                        スポーツ
                    </Button>
                </div>
            </div>
            <FooterDecisionBotton
                path="/MembersList"
                message="この内容で登録する"
            />
        </div>
    );
};
