import './MessageExchange.css';
import { Button } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ImageIcon from '@mui/icons-material/Image';
import SendIcon from '@mui/icons-material/Send';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FooterIcons } from '../Footer/FooterIcons/FooterIcons';

export const MessageExchange = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const path: string = location.state.path;
    const scroll_down = useRef<boolean>(false);
    const [message, set_message] = useState('');
    const [message_list, setList] = useState([
        <div style={{ listStyle: 'none' }}>{message}</div>,
    ]);

    const list_up = (message: string) => {
        if (message !== '') {
            const newList = [
                ...message_list,
                <div className="send_configuration">
                    <div className="send_message">{message}</div>
                </div>,
            ];
            setList(newList);
            set_message('');
            scroll_down.current = true;
        }
        let up_message_screen = document.getElementById('up_message_screen');
        if (up_message_screen !== null) {
            up_message_screen.className = 'main_content_common';
        }
    };

    const up_input = () => {
        let up_message_screen = document.getElementById('up_message_screen');
        if (up_message_screen !== null) {
            up_message_screen.className = 'up_message_screen';
        }
        console.log(up_message_screen);
    };
    useEffect(() => {
        if (scroll_down.current === true) {
            let target: any = document.getElementById('scroll-inner');
            target.scrollIntoView(false);
            scroll_down.current = false;
        }
    });
    return (
        <div>
            <Button onClick={() => navigate('/MembersList')}>戻る</Button>
            <div id="up_message_screen" className="main_content_common">
                <div id="scroll-inner">{message_list}</div>
            </div>
            <div className="message_list_footer">
                <PhotoCameraIcon
                    fontSize="large"
                    style={{ verticalAlign: '-11px', color: '#666' }}
                />
                <ImageIcon
                    fontSize="large"
                    style={{ verticalAlign: '-11px', color: '#666' }}
                />
                <input
                    className="message_list_input_box"
                    type="text"
                    value={message}
                    onChange={(event) => set_message(event.target.value)}
                    onClick={up_input}
                />
                <SendIcon
                    style={{
                        verticalAlign: '-11px',
                        color: '#666',
                        transform: 'rotate(-45deg)',
                        marginBottom: '3px',
                        marginLeft: '3px',
                    }}
                    fontSize="large"
                    onClick={() => list_up(message)}
                />
            </div>
            {/* <FooterIcons /> */}
        </div>
    );
};
