import { Button } from '@mui/material';
import * as React from 'react';
import './MessageList.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { FooterIcons } from '../Footer/FooterIcons/FooterIcons';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import test_A_search_user_image from '../Images/MembersList/25102258_s.jpg';
import test_B_search_user_image from '../Images/MembersList/25116697_s.jpg';
import test_C_search_user_image from '../Images/MembersList/25075585_s.jpg';
import test_D_search_user_image from '../Images/MembersList/24656762_s.jpg';

export const MessageList = () => {
    const member_list_items = [
        {
            nick_name: '電算 太郎',
            last_message_date: '10時10分',
            image: test_A_search_user_image,
            nice_count: '20',
            self_introduction: 'おはようございます。～～～～～～～～',
            online_status: '#99FF99',
        },
        {
            nick_name: '電算 花子',
            last_message_date: '昨日',
            image: test_B_search_user_image,
            nice_count: '20',
            self_introduction: 'お疲れ様です。～～～～～～～～',
            online_status: '#FF6666',
        },
        {
            nick_name: '電算 TEST',
            last_message_date: '10月10日',
            image: test_C_search_user_image,
            nice_count: '20',
            self_introduction: 'お疲れ様です。～～～～～～～～',
            online_status: '#99FF99',
        },
        {
            nick_name: '電算 太郎',
            last_message_date: '9月20日',
            image: test_D_search_user_image,
            nice_count: '20',
            self_introduction: 'お疲れ様です。～～～～～～～～',
            online_status: '#FF6666',
        },
    ];
    const members_list: any = [];
    for (let i = 0; i < member_list_items.length; i++) {
        members_list.push(
            <div className="message_list">
                <div className="message_top">
                    <div className="message_top_status">
                        <Brightness1Icon
                            style={{
                                color: member_list_items[i].online_status,
                            }}
                        />
                        <div>{member_list_items[i].nick_name}</div>
                        <div className="message_list_nice">
                            <div>いいね！</div>
                            <div>{member_list_items[i].nice_count}</div>
                        </div>
                    </div>
                    <div className="last_message_time">
                        {member_list_items[i].last_message_date}
                    </div>
                </div>
                <div>
                    <img
                        className="message_list_image"
                        src={member_list_items[i].image}
                    />
                </div>

                <div className="members_list_self-introduction_box">
                    {member_list_items[i].self_introduction}
                </div>
            </div>
        );
    }
    const navigate = useNavigate();
    return (
        <div>
            <div className="message_list_title">メッセージリスト</div>
            <div
                className="main_content_common"
                onClick={() =>
                    navigate('/MessageExchange', {
                        state: { path: '/MessageList' },
                        replace: false,
                    })
                }
            >
                {members_list}
            </div>

            <FooterIcons path="/MessageList" />
        </div>
    );
};
