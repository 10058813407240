import './MatchingListTop.css';
import { useNavigate } from 'react-router-dom';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BorderAllIcon from '@mui/icons-material/BorderAll';
/**
 * マッチングリストのリストタイプ、カードタイプを変更します。
 */
export const MatchingListTop = (props: any) => {
    let border_icon_color: string = '';
    let format_list_icon: string = '';
    if ('/MatchingMembersList' === props.path) {
        border_icon_color = '#ccc';
        format_list_icon = '#666';
    }
    if ('/MatchingMembersCard' === props.path) {
        border_icon_color = '#666';
        format_list_icon = '#ccc';
    }
    const navigate = useNavigate();
    return (
        <div className="matching_lists_top">
            <div>
                <BorderAllIcon
                    style={{
                        color: border_icon_color,
                    }}
                    onClick={() => navigate('/MatchingMembersCard')}
                    fontSize="large"
                />
                <FormatListBulletedIcon
                    style={{
                        color: format_list_icon,
                    }}
                    onClick={() => navigate('/MatchingMembersList')}
                    fontSize="large"
                />
            </div>
            <div>マッチングした人数　4人</div>
        </div>
    );
};
