import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MembershipRegistrationAgreement } from './components/MembershipRegistrationAgreement/MembershipRegistrationAgreement';
import { ServiceRegistration } from './components/ServiceRegistration/ServiceRegistration';
import { RegistrationConfirmation } from './components/RegistrationConfirmation/RegistrationConfirmation';
import { MembersList } from './components/MembersList/MembersList';
import { ConditionSpecification } from './components/ConditionSpecification/ConditionSpecification';
import { ResidenceSelectionScreen } from './components/ResidenceSelectionScreen/ResidenceSelectionScreen';
import { MatchingMembersList } from './components/MatchingMembersList/MatchingMembersList';
import { SearchMemberProfile } from './components/SearchMemberProfile/SearchMemberProfile';
import { AgeSelectionScreen } from './components/AgeSelectionScreen/AgeSelectionScreen';
import { MatchingMembersCard } from './components/MatchingMembersCard/MatchingMembersCard';
import { MatchingMemberProfile } from './components/MatchingMemberProfile/MatchingMemberProfile';
import { MessageList } from './components/MessageList/MessageList';
import { MessageExchange } from './components/MessageExchange/MessageExchange';
import { Mypage } from './components/Mypage/Mypage';
import { MypageMyProfile } from './components/MypageMyProfile/MypageMyProfile';
import { InterestedItemEdit } from './components/InterestedItemEdit/InterestedItemEdit';
import { MypageFootPrintsList } from './components/MypageFootPrintsList/MypageFootPrintsList';
import { MypageNiceFromMembersList } from './components/MypageNiceFromMembersList/MypageNiceFromMembersList';
import { MypageNiceApproval } from './components/MypageNiceApproval/MypageNiceApproval';
import { MatchingReturnMessage } from './components/MatchingReturnMessage/MatchingReturnMessage';
import { GenderSelectionScreen } from './components/GenderSelectionScreen/GenderSelectionScreen';
import { ExerciseMethodSelect } from './components/ExerciseMethodSelect/ExerciseMethodSelect';
import { InterestingThingsSelect } from './components/InterestingThingsSelect/InterestingThingsSelect';

/**
 * アプリケーション全体のコンポーネントを作成します。
 * @returns 作成したコンポーネントを返します。
 */
function App(): React.ReactElement {
    return (
        <div className="App">
            <header className="App-header">Uマッチング(仮)</header>
            <BrowserRouter>
                <Routes>
                    <Route
                        path={'/'}
                        element={<MembershipRegistrationAgreement />}
                    ></Route>
                    <Route
                        path={'/ServiceRegistration/'}
                        element={<ServiceRegistration />}
                    ></Route>
                    <Route
                        path={'/ExerciseMethodSelect/'}
                        element={<ExerciseMethodSelect />}
                    ></Route>
                    <Route
                        path={'/InterestingThingsSelect/'}
                        element={<InterestingThingsSelect />}
                    ></Route>
                    <Route
                        path={'/RegistrationConfirmation/'}
                        element={<RegistrationConfirmation />}
                    ></Route>
                    <Route
                        path={'/MembersList/'}
                        element={<MembersList />}
                    ></Route>
                    <Route
                        path={'/ConditionSpecification/'}
                        element={<ConditionSpecification />}
                    ></Route>
                    <Route
                        path={'/ResidenceSelectionScreen/'}
                        element={<ResidenceSelectionScreen />}
                    ></Route>
                    <Route
                        path={'/AgeSelectionScreen/'}
                        element={<AgeSelectionScreen />}
                    ></Route>
                    <Route
                        path={'/GenderSelectionScreen/'}
                        element={<GenderSelectionScreen />}
                    ></Route>
                    <Route
                        path={'/SearchMemberProfile/'}
                        element={<SearchMemberProfile />}
                    ></Route>
                    <Route
                        path={'/MatchingMembersList/'}
                        element={<MatchingMembersList />}
                    ></Route>
                    <Route
                        path={'/MatchingMembersCard/'}
                        element={<MatchingMembersCard />}
                    ></Route>
                    <Route
                        path={'/MatchingMemberProfile/'}
                        element={<MatchingMemberProfile />}
                    ></Route>
                    <Route
                        path={'/MessageList/'}
                        element={<MessageList />}
                    ></Route>
                    <Route
                        path={'/MessageExchange/'}
                        element={<MessageExchange />}
                    ></Route>
                    <Route path={'/Mypage/'} element={<Mypage />}></Route>
                    <Route
                        path={'/MypageMyProfile/'}
                        element={<MypageMyProfile />}
                    ></Route>
                    <Route
                        path={'/InterestedItemEdit/'}
                        element={<InterestedItemEdit />}
                    ></Route>
                    <Route
                        path={'/MypageFootPrintsList/'}
                        element={<MypageFootPrintsList />}
                    ></Route>
                    x
                    <Route
                        path={'/MypageNiceFromMembersList/'}
                        element={<MypageNiceFromMembersList />}
                    ></Route>
                    <Route
                        path={'/MypageNiceApproval/'}
                        element={<MypageNiceApproval />}
                    ></Route>
                    <Route
                        path={'/MatchingReturnMessage/'}
                        element={<MatchingReturnMessage />}
                    ></Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
